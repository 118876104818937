import { OrthographicCamera, PlaneGeometry, Mesh, LinearFilter, RGBAFormat, Scene, WebGLRenderTarget, HalfFloatType, AmbientLight, MeshStandardMaterial } from 'three';

import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import GlitchMaterial, { GLITCH_SETTINGS } from '../_app/cuchillo/3D/materials/GlitchMaterial';

export default class RenderTargetGlitchSketch extends WebGLSketch {
    static renderScene;

    static renderPlane;
    static renderPlaneGeometry;
    static renderPlaneMaterial;
    static renderPlaneScene;
    static renderPlaneCamera;

    static init() {
        super.init();

        // Render target stuff
        const renderSceneWidth = window.innerWidth;
        const renderSceneHeight = window.innerHeight;
        const parameters = {};

        this.renderScene = new WebGLRenderTarget(renderSceneWidth, renderSceneHeight, parameters);

        this.renderPlaneGeometry = new PlaneGeometry(1, 1);
        this.renderPlaneMaterial = new GlitchMaterial();
        this.renderPlaneMaterial.uniforms.texture1.value = this.renderScene.texture;

        if (GLITCH_SETTINGS.glitch) {
            this.renderPlaneMaterial.uniforms.time.value = this.time() * 5;
            this.renderPlaneMaterial.uniforms.distortion.value = GLITCH_SETTINGS.distortion;
            this.renderPlaneMaterial.uniforms.distortion2.value = GLITCH_SETTINGS.distortion2;
            this.renderPlaneMaterial.uniforms.distortionScale.value = GLITCH_SETTINGS.distortionScale;
            this.renderPlaneMaterial.uniforms.distortionScale2.value = GLITCH_SETTINGS.distortionScale2;
            this.renderPlaneMaterial.uniforms.distortionScale2.value = GLITCH_SETTINGS.mixBW;
        }

        this.renderPlane = new Mesh(this.renderPlaneGeometry, this.renderPlaneMaterial);

        this.renderPlaneScene = new Scene();
        this.renderPlaneScene.add(this.renderPlane);
        this.renderPlaneCamera = new OrthographicCamera(
            -window.innerWidth / 2,
            window.innerWidth / 2,
            window.innerHeight / 2,
            -window.innerHeight / 2,
            -1,
            1
        );
    }

    static update() {
        super.update();

        if (GLITCH_SETTINGS.glitch) {
            this.renderPlaneMaterial.uniforms.time.value = this.time() * 5;
            this.renderPlaneMaterial.uniforms.distortion.value = GLITCH_SETTINGS.distortion;
            this.renderPlaneMaterial.uniforms.distortion2.value = GLITCH_SETTINGS.distortion2;
            this.renderPlaneMaterial.uniforms.distortionScale.value = GLITCH_SETTINGS.distortionScale;
            this.renderPlaneMaterial.uniforms.distortionScale2.value = GLITCH_SETTINGS.distortionScale2;
        } else {
            this.renderPlaneMaterial.uniforms.time.value = 0;
            this.renderPlaneMaterial.uniforms.distortion.value = 0;
            this.renderPlaneMaterial.uniforms.distortion2.value = 0;
            this.renderPlaneMaterial.uniforms.distortionScale.value = 0;
            this.renderPlaneMaterial.uniforms.distortionScale2.value = 0;
        }

        this.renderPlaneMaterial.uniforms.mixBW.value = GLITCH_SETTINGS.mixBW;
    }

    static render() {
        if (!this.renderScene) {
            super.render();
            return;
        }

        this.renderer.setRenderTarget(this.renderScene);
        this.renderer.render(this.scene, this.camera);

        this.renderer.setRenderTarget(null);
        this.renderer.render(this.renderPlaneScene, this.renderPlaneCamera);
    }

    static resize() {
        super.resize();

        const width = window.innerWidth;
        const height = window.innerHeight;

        this.renderScene.setSize(width, height);

        this.renderPlane.scale.set(width, height, 1);

        this.renderPlaneCamera.left = -width / 2;
        this.renderPlaneCamera.right = width / 2;
        this.renderPlaneCamera.top = height / 2;
        this.renderPlaneCamera.bottom = -height / 2;
        this.renderPlaneCamera.aspect = window.innerWidth / window.innerHeight;
        this.renderPlaneCamera.updateProjectionMatrix();
    }
}
