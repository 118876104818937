import gsap from 'gsap';

import WebGLObject from '../_app/cuchillo/3D/WebGLObject';
import { SETTINGS } from './constants';
import GeometryController from '../_app/cuchillo/3D/GeometryController';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';

export default class EyeGLB extends WebGLObject {
    object;
    diffusion;
    normal;
    roughness;
    metalness;
    envMap;

    model;
    matcap;

    factor = 1;

    constructor(opts = {}) {
        super(opts);

        this.model = opts.model;
        this.matcap = opts.matcap;
        this.diffusion = opts.diffusion;
        this.normal = opts.normal;
        this.roughness = opts.roughness;
        this.metalness = opts.metalness;
        this.envMap = opts.envMap;

        this.sizeFactor = opts.cols ? opts.cols : SETTINGS.factor;
    }

    init() {
        return new Promise((resolve, reject) => {
            GeometryController.load(
                {
                    src: this.model,
                    call: (gltf) => {
                        this.object = gltf;

                        this.mesh = gltf.scene.children[0];
                        this.add(gltf.scene);

                        this.rot.x = -Math.PI;
                        this.mesh.rotation.x = -Math.PI;

                        this.size.copy(this.mesh.scale);

                        this.resize();

                        resolve();
                    }
                },
                undefined,
                (error) => {
                    reject(error);
                });
        });
    }

    update(mouse) {
        if (!this.visible) return;

        const center = {
            x: this.mesh.position.x * this.factor,
            y: this.mesh.position.y * this.factor
        }

        // Group rotation
        this.mouse.x = Maths.map(mouse.x, center.x - Metrics.CENTER_X, center.x + Metrics.CENTER_X, -SETTINGS.mouseX, SETTINGS.mouseX);
        this.mouse.y = - Maths.map(mouse.y, center.y - Metrics.CENTER_Y, center.y + Metrics.CENTER_Y, -SETTINGS.mouseY, SETTINGS.mouseY);

        const rotationX = Maths.toRadians(this.mouse.y);
        const rotationY = Maths.toRadians(this.mouse.x);
        this.rot.x = rotationX - Math.PI;
        this.rot.y = -rotationY * 1.25;

        this.mesh.rotation.x = Maths.lerp(this.mesh.rotation.x, this.rot.x, this.opts.rotFactor);
        this.mesh.rotation.y = Maths.lerp(this.mesh.rotation.y, this.rot.y, this.opts.rotFactor);
    }

    resize(offsetX = 0, offsetY = 0, offsetScale = 1) {
        if (!this.object) return;

        const { x, y, width, height } = this.dom.getBoundingClientRect();

        if (this.hasMove) {
            const position = this.domPositionTo3D(x, y);
            this.position.x = (position.x + width * .5) - offsetX;
            this.position.y = (position.y - height * .5) - offsetY;
            this.pos.x = this.position.x;
            this.pos.y = this.position.y;
        } else {
            this.position.x = 0;
            this.position.y = 0;
            this.pos.x = 0;
            this.pos.y = 0;
        }

        if (this.opts.cols) {
            this.factor = (width / this.opts.cols);
            this.object.scene.scale.set(this.factor, this.factor, this.factor);
        } else {
            this.factor = width / SETTINGS.factor;
            this.object.scene.scale.set(this.factor * this.size.x, this.factor * this.size.y, this.factor * this.size.z);
        }
    }
}
