import gsap, { Power3 } from 'gsap';
import { AmbientLight, SpotLight, Vector3, Color, Vector2, Group } from 'three';
import { GetBy } from '../_app/cuchillo/core/Element';
import WebGLGroup from '../_app/cuchillo/3D/WebGLGroup';
import { FLOTATION_SETTINGS, LIGHT_SETTINGS, SETTINGS } from './constants';
import RenderTargetGlitchSketch from './RenderTargetGlitchSketch';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { isMobile, isSmartphone } from '../_app/cuchillo/core/Basics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import EyeGLB from './EyeGLB';
import HeadGLB from './HeadGLB';
import AnimationController from '../_app/cuchillo/3D/AnimationController';
import Main from '../main';

export default class Scene {
    static _running = false;
    static _initialized = false;
    static _rot = 0;
    static _tick = 0;
    static _seed = 0;

    static mouse = new Vector2();

    static dom;
    static domLogo;

    static head;
    static leftEye;
    static rightEye;

    static mainGroup = new Group();
    static group = new WebGLGroup({ xFactor: .035, rotFactor: .082 });
    static rotationGroup = new WebGLGroup({
        xFactor: .03,
        yFactor: .045,
        zFactor: .05,
    });
    static flotationGroup = new WebGLGroup({
        xFactor: .035,
        yFactor: .088,
        zFactor: .05,
        rotFactor: .023
    });
    static material;
    static logoMaterial;

    static ambientLight;
    static mouseLight;
    static mouseLightTarget = new Vector3();
    static lightColor = new Color(LIGHT_SETTINGS.color);

    static init() {
        RenderTargetGlitchSketch.add(this.mainGroup);
        this.mainGroup.add(this.group)
        this.group.add(this.flotationGroup);
        this.flotationGroup.add(this.rotationGroup);

        this.init3dItems();
    }

    static start() {
        if (!this._running) this._running = true;
    }

    static stop() {
        if (this._running) this._running = false;
    }

    static add(element) {
        this.rotationGroup.add(element);
    }

    static remove(element) {
        this.rotationGroup.remove(element);
    }

    static init3dItems() {
        const dom = GetBy.selector('#scene-target [data-3d-head]')[0];
        const domLogo = GetBy.selector('#scene-target [data-3d-name]')[0];
        this.dom = dom;
        this.domLogo = domLogo;

        this.ambientLight = new AmbientLight('#ffffff', 3);
        RenderTargetGlitchSketch.add(this.ambientLight);

        // if (!isMobile) {
        this.lightColor.set(LIGHT_SETTINGS.color);

        this.fixedLight = new SpotLight(this.lightColor, 1, 0, Math.PI / 8, 0.5, 2);
        this.fixedLight.position.set(0, 0, RenderTargetGlitchSketch.defaults.cameraPos.z);
        this.fixedLight.target.position.set(0, 0, 0);
        this.fixedLight.intensity = LIGHT_SETTINGS.intensity * 2;
        this.fixedLight.angle = Maths.toRadians(LIGHT_SETTINGS.angle * 3);
        this.fixedLight.penumbra = LIGHT_SETTINGS.penumbra * 3;
        this.fixedLight.decay = LIGHT_SETTINGS.decay;
        this.fixedLight.visible = true; //LIGHT_SETTINGS.visible;
        RenderTargetGlitchSketch.add(this.fixedLight);
        RenderTargetGlitchSketch.add(this.fixedLight.target);

        this.mouseLight = new SpotLight(this.lightColor, 1, 0, Math.PI / 8, 0.5, 2);
        this.mouseLight.position.set(0, 0, RenderTargetGlitchSketch.defaults.cameraPos.z);
        this.mouseLight.target.position.set(0, 0, 0);
        this.mouseLight.intensity = LIGHT_SETTINGS.intensity * 1.5;
        this.mouseLight.angle = Maths.toRadians(LIGHT_SETTINGS.angle * .75);
        this.mouseLight.penumbra = LIGHT_SETTINGS.penumbra * 3;
        this.mouseLight.decay = LIGHT_SETTINGS.decay;
        this.mouseLight.visible = LIGHT_SETTINGS.visible;

        RenderTargetGlitchSketch.add(this.mouseLight);
        RenderTargetGlitchSketch.add(this.mouseLight.target);
        //  }

        // MODEL 
        const modelName = '/assets/models/head_12.glb';
        const animations = '/assets/models/head_animations_06.gltf';
        this.head = new HeadGLB({
            model: modelName,
            animations,
            dom,
            cols: 4,
            hasMove: false
        });
        this.head.init().then(() => {
            this.rotationGroup.add(this.head);
            //this.resize();
            //this.loop();

            //setTimeout(()=> {this.head.loadAnimations()},10000);
            Main.items3DLoaded();
        });

        const leftEyeName = '/assets/models/eye_left_opt.glb';
        this.leftEye = new EyeGLB({
            model: leftEyeName,
            dom,
            cols: 4,
            hasMove: false,
            rotFactor: .1
        });
        this.leftEye.init().then(() => {
            this.rotationGroup.add(this.leftEye);
            //this.resize();
            //this.loop();
        });

        const rightEyeName = '/assets/models/eye_right_opt.glb';
        this.rightEye = new EyeGLB({
            model: rightEyeName,
            dom,
            cols: 4,
            hasMove: false,
            rotFactor: .1
        });
        this.rightEye.init().then(() => {
            this.rotationGroup.add(this.rightEye);
            //this.resize();
            //this.loop();
        });

        const randX = Maths.maxminRandom(.2 * Math.PI, .1 * Math.PI);
        const randY = Maths.maxminRandom(3 * Math.PI, 1.5 * Math.PI);
        const randZ = Maths.maxminRandom(.5 * Math.PI, .1 * Math.PI);
        gsap.set(this.mainGroup.position, { y: -2 * window.innerHeight });

        gsap.set(this.mainGroup.rotation, { x: randX, y: randY, z: randZ });

    }

    static show(cb = () => { }) {
        let delay = 0;
        this._initialized = true;

        GetBy.selector('#scene-target [data-3d-name]')[0].classList.add("--add-color");

        //gsap.to(GetBy.selector('#scene-target [data-3d-name] .icon--logo'), { opacity: 1, duration: 2.75, delay: 2, ease: Power3.easeInOut });

        const { x, y, width, height } = this.dom.getBoundingClientRect();

        const position = Functions.domPositionTo3D(x, y);
        // const posY = (position.y - height * .5);
        const posX = (position.x + width * .5);

        delay += .3;
        this.group.position.x = posX;
        gsap.to(this.mainGroup.position, { y: 0, duration: 4, ease: Ease.EASE_CUCHILLO_IN_OUT, delay });

        gsap.to(this.mainGroup.rotation, { z: 0, duration: 3, ease: Ease.EASE_CUCHILLO_IN_OUT, delay });
        gsap.to(this.mainGroup.rotation, {
            y: 0,
            x: 0,
            duration: 4,
            ease: Ease.EASE_CUCHILLO_IN_OUT,
            delay,
            onComplete: () => {
                this._initialized = true;
                cb();
            }
        });
    }

    static hide() { }

    static loop() {
        if (!this._running || !this._initialized) return;

        // Group movement with target
        const { x, y, width, height } = this.dom.getBoundingClientRect();
        const center = Functions.domPositionTo3D(x + width * .5, y + height * .5);
        this.group.pos.y = center.y;
        this.group.pos.x = center.x;

        if (!isSmartphone) this.group.rot.y = center.x > 0 ? -.35 : center.x < 0 ? .35 : 0;

        this.group.update();

        // Group rotation with mouse
        // if (SETTINGS.mouse) {

        let pos = Interaction.positions.mouse;
        if (isMobile) pos = Interaction.positions.mouse;

        //console.log(pos)

        const mouse = Functions.domPositionTo3D(pos.x, pos.y);

        // Lights
        //if (!isMobile) {
        // this.mouseLightTarget.set(mouse.x, mouse.y, 0);
        // this.mouseLight.target.position.lerp(this.mouseLightTarget, LIGHT_SETTINGS.lerp);
        this.mouseLightTarget.set(mouse.x, mouse.y, 0);
        this.mouseLight.target.position.lerp(this.mouseLightTarget, LIGHT_SETTINGS.lerp);

        // this.fixedLight.target.position.set(0, 0, 0);

        // this.mouseLight.intensity = LIGHT_SETTINGS.intensity;
        // this.mouseLight.angle = Maths.toRadians(LIGHT_SETTINGS.angle);
        // this.mouseLight.penumbra = LIGHT_SETTINGS.penumbra;
        // this.mouseLight.decay = LIGHT_SETTINGS.decay;
        // this.mouseLight.visible = LIGHT_SETTINGS.visible;
        // }

        // Group rotation
        let mx = Maths.map(mouse.x, center.x - Metrics.CENTER_X, center.x + Metrics.CENTER_X, -SETTINGS.mouseX, SETTINGS.mouseX);
        let my = - Maths.map(mouse.y, center.y - Metrics.CENTER_Y, center.y + Metrics.CENTER_Y, -SETTINGS.mouseY, SETTINGS.mouseY);
        this.mouse.x = Maths.lerp(this.mouse.x, mx, SETTINGS.mouseLerp);
        this.mouse.y = Maths.lerp(this.mouse.y, my, SETTINGS.mouseLerpY);

        const rotationX = Maths.toRadians(this.mouse.y);
        const rotationY = Maths.toRadians(this.mouse.x);
        this.rotationGroup.rot.x = Maths.clamp(rotationX, -.3, .3);
        this.rotationGroup.rot.y = Maths.clamp(rotationY, -.3, .3);

        const mousePosX = Maths.map(mouse.x, - Metrics.CENTER_X, Metrics.CENTER_X, -SETTINGS.posX, SETTINGS.posX);
        const mousePosY = Maths.map(mouse.y, - Metrics.CENTER_Y, Metrics.CENTER_Y, -SETTINGS.posY, SETTINGS.posY);
        const dist = Math.abs(0 - mouse.x);
        const mousePosZ = Maths.map(dist, 0, Metrics.WIDTH / 2, SETTINGS.posZ, 0);
        this.rotationGroup.pos.x = -mousePosX;
        this.rotationGroup.pos.y = -mousePosY;
        this.rotationGroup.pos.z = -mousePosZ;

        this.rotationGroup.update();

        // Eyes movement and render
        this.leftEye.update(mouse);
        this.rightEye.update(mouse);
        // }

        // Flotation
        // if (FLOTATION_SETTINGS.flotate) {
        const time = RenderTargetGlitchSketch.time();
        const mod = isSmartphone ? 1 : 1;
        this.flotationGroup.pos.x = Math.sin(time * .63) * (FLOTATION_SETTINGS.amplitudeX * mod);
        this.flotationGroup.pos.y = Math.cos(time) * (FLOTATION_SETTINGS.amplitudeY * mod);
        this.flotationGroup.pos.z = Math.sin(time) * (FLOTATION_SETTINGS.amplitudeZ * mod);
        this.flotationGroup.rot.x = Math.sin(time * .75) * Math.PI * FLOTATION_SETTINGS.rotIntensity * .2 * mod;
        this.flotationGroup.rot.z = Math.sin(time * .55) * Math.PI * FLOTATION_SETTINGS.rotIntensity * .66 * mod;
        // } else {
        //     this.flotationGroup.pos.x = 0;
        //     this.flotationGroup.pos.y = 0;
        //     this.flotationGroup.pos.z = 0;
        //     this.flotationGroup.rot.x = 0;
        // }
        this.flotationGroup.update();

        // Head render
        AnimationController.loop();
    }

    static resize() {
        if (this.fixedLight) {
            this.fixedLight.position.x = 0;
            this.fixedLight.position.y = 0;
            this.fixedLight.position.z = RenderTargetGlitchSketch.defaults.cameraPos.z;
        }

        // if (!isSmartphone) {
        this.mouseLight.position.x = 0;
        this.mouseLight.position.y = 0;
        this.mouseLight.position.z = RenderTargetGlitchSketch.defaults.cameraPos.z;
        // }

        if (this.head) this.head.resize();
        if (this.leftEye) this.leftEye.resize();
        if (this.rightEye) this.rightEye.resize();
    }

    static dispose() {
        this.rotationGroup.remove(this.head);
        this.head.dispose();

        this.rotationGroup.remove(this.leftEye);
        this.leftEye.dispose();

        this.rotationGroup.remove(this.rightEye);
        this.rightEye.dispose();

        this.flotationGroup.add(this.rotationGroup);
        this.rotationGroup.dispose();

        this.group.remove(this.flotationGroup);
        this.flotationGroup.dispose();

        RenderTargetGlitchSketch.add(this.group);
        this.group.dispose();

        RenderTargetGlitchSketch.remove(this.ambientLight);
        this.ambientLight.dispose();

        RenderTargetGlitchSketch.remove(this.fixedLight);
        RenderTargetGlitchSketch.remove(this.fixedLight.target);
        this.fixedLight.dispose();

        if (isSmartphone) {
            RenderTargetGlitchSketch.remove(this.mouseLight);
            RenderTargetGlitchSketch.remove(this.mouseLight.target);
            this.mouseLight.dispose();
        }
    }
}
