import { MeshBasicMaterial, TextureLoader, Vector3, PlaneGeometry, BoxGeometry, DoubleSide, MeshMatcapMaterial } from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

export const TEXTURE_LOADER = new TextureLoader();
//export const OBJ_LOADER = new OBJLoader();
export const GLTF_LOADER = new GLTFLoader();
export const DRACO_LOADER = new GLTFLoader();
DRACO_LOADER.setDRACOLoader(dracoLoader);
export const FONT_LOADER = new FontLoader();

export const CENTER = new Vector3();

export const SETTINGS = {
    opacity: 1,
    fov: 50,
    near: 1,
    far: 15000,
    factor: 20,
    camera2D: true,
    cameraPos: new Vector3(0, 0, 1500),
    lookAt: new Vector3(0, 0, 1500),
    controls: false,
    clearColor: '#02024c',
    clearColorAlpha: 0,
    noiseIntensity: 1.130,
    mouse: true,
    mouseX: 15.46,
    mouseY: 9.23,
    mouseLerp: .04,
    mouseLerpY: .035,
    posX: 30,
    posY: 35,
    posZ: 150,
    cameraLookAtLerp: .062,
    cameraLerp: .04,
    groupRotation: false,
    cameraRotation: false,
    controls: false,
}

export const ANIMATION_SETTINGS = {
    timeScale: .65,
    time: 5
}

export const FLOTATION_SETTINGS = {
    amplitudeX: 25,
    amplitudeY: 15,
    amplitudeZ: 10,
    rotIntensity: .05,
    flotate: true
}

export const LIGHT_SETTINGS = {
    color: '#F1B6FF',
    angle: 18,
    intensity: 3.75,
    maxDistance: 2000,
    penumbra: .41,
    decay: 0,
    visible: true,
    lerp: .045
}

export const MATCAP_MATERIAL = new MeshMatcapMaterial();
export const BASIC_MATERIAL = new MeshBasicMaterial({ color: 0x0000FF, side: DoubleSide });
export const BOX_GEOMETRY = new BoxGeometry();
export const PLANE_GEOMETRY = new PlaneGeometry();
