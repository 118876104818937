import { GetBy } from '../core/Element';
// import Cookies from './Cookies';

export default class Language {
  static actual;
  static langs;
  static default = 'en';

  static init() {
    this.actual = document.documentElement.lang;
    this.getAlternates();
    // this.checkUserPreferences();
  }

  static update() {
    this.actual = document.documentElement.lang;
    // Cookies.add('language', this.actual);
  }

  static getAlternates() {
    this.langs = {};
    [...GetBy.selector('[rel="alternate"]')].map(item => {
      this.langs[item.getAttribute('hreflang')] = item.getAttribute('href');
    });
  }

  static getLangHref(__lang) {
    let href = this.langs[this.default];

    Object.keys(this.langs).map(key => {
      if (__lang.includes(key)) {
        href = this.langs[key];
      }
    });

    return href;
  }

  // static checkUserPreferences() {
  //   const cookieLang = Cookies.get('language');
  //   const cookieHref = this.langs[cookieLang];
  //   const browserLang = navigator.language;

  //   if (cookieLang) {
  //     if (!!cookieLang != Language.actual && cookieHref) {
  //       window.location.replace(cookieHref);
  //       console.log('Redirect to cookie: ' + cookieHref);
  //       return;
  //     }
  //   } else if (!browserLang.includes(Language.actual)) {
  //     const newHref = this.getLangHref(browserLang);
  //     if (!!newHref) {
  //       window.location.replace(newHref);
  //       console.log('Redirect to new: ' + newHref);
  //       return;
  //     }
  //   }

  //   console.log('Seguimos en: ' + Language.actual);
  // }
}
