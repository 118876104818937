import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';
import Sidemenu from '../layout/Sidemenu';
import Bio from '../layout/Bio';
import Scene from '../3D/Scene';
import AnimationController from '../_app/cuchillo/3D/AnimationController';
import Overlay from '../layout/Overlay';

export default class Data extends Default {
  content;

  constructor() {
    super();

    document.body.classList.add('--data');
  }

  beforeShow() {
    super.beforeShow();
    Sidemenu.hide();
    Overlay.directHide();
    if (!this.isFirstTime) Bio.show();
  }

  afterShow() {
    super.afterShow();

    if (this.isFirstTime) Bio.show();

    AnimationController.playPageAnimation('sonrisa');
  }

  afterHide() {
    super.afterHide();

    document.body.classList.remove('--data');
  }
}

ControllerPage._addPage('data', Data);
