import AnimationController from '../_app/cuchillo/3D/AnimationController';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Bio from '../layout/Bio';
import Overlay from '../layout/Overlay';
import Sidemenu from '../layout/Sidemenu';
import Default from './Default';

export default class Legal extends Default {

  idTimer

  constructor() {
    super();

    document.body.classList.add('--home');
  }

  beforeShow() {
    super.beforeShow();
    Sidemenu.hide();
    Bio.hide();
    Overlay.directHide();
  }

  afterShow() {
    super.afterShow();

    AnimationController.play();
  }

  playAnimation() {
    // console.log("playRandom")
    AnimationController.play();

    clearTimeout(this.idTimer);
    this.idTimer = setTimeout(() => { this.playIdle(); }, 2000);
  }

  playIdle() {
    // console.log("playIdle")
    AnimationController.playIdle();

    clearTimeout(this.idTimer);
    this.idTimer = setTimeout(() => { this.playAnimation(); }, 2000);
  }

  afterHide() {
    clearTimeout(this.idTimer);
    super.afterHide();
    document.body.classList.remove('--home');
  }
}

ControllerPage._addPage('legal', Legal);
