import { GetBy } from '../_app/cuchillo/core/Element';
import _Wrap from '../_app/cuchillo/layout/Wrap';
import gsap, { Power3, Power4 } from "gsap";

export default class Overlay {
  static container = GetBy.class("__main-overlay")[0];
  static blue = GetBy.class("__blue", this.container)[0];
  static pink = GetBy.class("__pink", this.container)[0];
  static black = GetBy.class("__black", this.container)[0];
  static isShow = false;
  static _direction = 1;

  static get direction() { return this._direction; }
  static set direction(__direction) {
    this._direction = __direction;
    
    if(__direction === 1) {
      this.container.classList.remove("--invert");
    } else {
      this.container.classList.add("--invert");
    }
  }

  static show(__call) {
    this.direction = 1;
    this.isShow = true;
    gsap.to(this.blue,{scaleY:1, duration:.8, ease:Power3.easeIn})
    gsap.to(this.pink,{scaleY:1, duration:.8, delay:.1, ease:Power3.easeIn})
    gsap.to(this.black,{scaleY:1, duration:.8, delay:.2, ease:Power4.easeIn, onComplete:()=> {
      if(__call) __call();
    }})
  }

  static hide(__call, __callHide) {
    if(!this.isShow) {
      this.show(()=> {this.hide(__call)});
      return;
    }
    __call();
    this.direction = -1;
    this.isShow = false;

    
    gsap.to(this.black,{scaleY:0, duration:.6, delay:0, ease:Power4.easeOut})
    gsap.to(this.pink,{scaleY:0, duration:.6, delay:.1, ease:Power4.easeOut})
    gsap.to(this.blue,{scaleY:0, duration:.8, delay:.1, ease:Power4.easeOut, onComplete:()=> {
      if(__callHide) __callHide();
    }})
  }

  static directHide() {
    this.isShow = false;
    gsap.set(this.blue,{scaleY:0})
    gsap.set(this.pink,{scaleY:0})
    gsap.set(this.black,{scaleY:0})
  }
}


