import gsap, { Power3 } from 'gsap';

import { Shffl } from '../_app/cuchillo/components/Shffl';
import _Header from '../_app/cuchillo/layout/Header';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';

export default class Header extends _Header {
  static hovers = [];
  static footer = GetBy.id("Footer");

  static options = {
    show: {
      duration: .6,
      delay: 0,
      ease: Power3.easeInOut
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power3.easeInOut
    }
  }

  static init() {
    super.init();
    this.setShffleBtns();

    gsap.set(this.container, { y: '-100%' });
  }

  static show__effect() {
    gsap.to(this.container, {
      y: 0,
      duration: this.options.show.duration,
      delay: this.options.show.delay,
      ease: this.options.show.duration.ease
    });

    gsap.to(this.footer, {
      alpha: 1,
      duration: this.options.show.duration,
      delay: this.options.show.delay * 2,
      ease: this.options.show.duration.ease
    });
  }


  static setShffleBtns() {
    const btns = GetBy.selector('[data-header-shffl]');

    this._hoverIn = (e) => this.hovers[e.target.id].play();
    this._hoverOut = (e) => this.hovers[e.target.id].reverse();

    for (let i = 0; i < btns.length; i++) {
      const btn = btns[i];
      const target = GetBy.class('__target', btn)[0];

      const shffl = new Shffl(target, {
        origin: Shffl.LEFT,
        duration: .6,
        textFrom: target.innerHTML,
        textTo: target.innerHTML
      });

      this.hovers[btn.id] = shffl;
      btn.addEventListener(Basics.mouseOver, this._hoverIn);
      btn.addEventListener(Basics.mouseOut, this._hoverOut);
    }
  }
}
