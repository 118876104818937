import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

import { GetBy, C } from '../core/Element';
import { Basics } from '../core/Basics';
import { Shffl } from '../components/Shffl';

export const Acordions = {
  _acordions: [],

  init() {
    this._acordions = [];
    C.forEach('[data-acordion]', (e) => { this._acordions.push(new Acordion(e)); })
  },

  dispose() {
    this._acordions.map(acc => acc.dispose());
  },

  toggle: function (__btn) {
    const ACORDION = __btn.parentNode;
    ACORDION.setAttribute('aria-expanded', ACORDION.getAttribute('aria-expanded') === 'false');
  },

  hide: function () {
    this._acordions.map(acc => acc.hide());
  },

  countOpen: function () {
    return this._acordions.filter(acc => acc.isOpen).length;
  },

  resize() {
    this._acordions.map(acc => acc.resize());
  }
};

export class Acordion {
  container;
  group;
  toggle;
  isOpen = false;
  hover;
  cb;
  lines = [];
  shffl = [];

  constructor(__container, __cb = () => { }) {
    this.container = __container;
    this.toggle = GetBy.selector('[data-acordion-toggle]', __container)[0];
    this.group = GetBy.selector('[data-acordion-group]', __container)[0];
    this.isOpen = this.container.getAttribute('aria-expanded') || false;
    this.container.setAttribute('aria-expanded', this.isOpen);

    this.cb = __cb;

    this._toggle = (e) => this.toggleState(e);
    this.toggle.addEventListener(Basics.clickEvent, this._toggle);

    this.setLines();
    this.setShffle();
    this.resize();
  }

  setLines() {
    new SplitText(GetBy.selector('p', this.container), { type: 'lines', linesClass: '__line', tag: 'p' });
    new SplitText(GetBy.selector('li', this.container), { type: 'lines', linesClass: '__line', tag: 'p' });

    // const lis = GetBy.selector('li', this.container);
    // for (let i = 0; i < lis.length; i++) lis[i].classList.add('__line');

    this.lines = GetBy.class('__line', this.container);
    for (let i = 0; i < this.lines.length; i++) {
      const txt = this.lines[i];
      const shffl = new Shffl(txt, {
        origin: Shffl.LEFT,
        duration: .6,
        textFrom: '>:: text',
        textTo: txt.innerHTML
      });

      this.shffl.push(shffl);
    }

    if (this.isOpen) {
      this.shffl.map((line, i) => {
        line.play();
        this.lines[i].classList.add('--animated');
      });
    }
  }

  setShffle() {
    this._hoverIn = () => this.hover.play();
    this._hoverOut = () => this.hover.reverse();

    const target = GetBy.class('__target', this.toggle)[0];
    const shffl = new Shffl(target, {
      origin: Shffl.LEFT,
      duration: .5,
      textFrom: target.innerHTML,
      textTo: target.innerHTML
    });

    this.hover = shffl;
    this.toggle.addEventListener(Basics.mouseOver, this._hoverIn);
    this.toggle.addEventListener(Basics.mouseOut, this._hoverOut);
  }

  toggleState(e) {
    e.preventDefault();

    if (this.isOpen) this.hide();
    else this.show();
  }

  show() {
    this.isOpen = true;
    this.container.setAttribute('aria-expanded', 'true');

    this.shffl.map((line, i) => {
      line.restart();
      this.lines[i].classList.remove('--animated');

      gsap.delayedCall(i * .05 + .2, () => {
        line.play();
        this.lines[i].classList.add('--animated');
      });
    });

    this.cb();
  }

  hide() {
    this.isOpen = false;
    this.shffl.map(line => {
      line.end();
    });
    this.container.setAttribute('aria-expanded', 'false');
  }

  resize() {
    for (let i = 0; i < this.lines.length; i++) {
      const element = this.lines[i];
      element.style = '';
      element.style.height = element.offsetHeight + 'px';
    }

    this.container.style.setProperty('--height-close', this.toggle.offsetHeight + 'px');
    this.container.style.setProperty('--height-open', this.group.offsetHeight + 'px');
  }

  dispose() {
    this.toggle.removeEventListener(Basics.clickEvent, this._toggle);
    this.toggle.removeEventListener(Basics.mouseOver, this._hoverIn);
    this.toggle.removeEventListener(Basics.mouseOut, this._hoverOut);
  }
}
