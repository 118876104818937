import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';

class ScrollItem__BlockAbout extends VScroll_Item {
  shffl = [];
  hovers = [];
  btns = [];

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.setShffleBtns();
    this.setupText();

    this.onShow = () => {
      this.shffl.map((text, i) => {
        gsap.delayedCall(i * .09, () => {
          text.play();
        });
      });
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  setupText() {
    new SplitText(GetBy.selector('.__content .top p', this.item), { type: 'lines', linesClass: '__target', tag: 'p' });

    const lines = GetBy.class('__target', this.item);
    for (let i = 0; i < lines.length; i++) {
      const txt = lines[i];
      const shffl = new Shffl(txt, {
        origin: Shffl.LEFT,
        duration: 1,
        textFrom: '',
        textTo: txt.innerText
      });

      this.shffl.push(shffl);
    }
  }

  setShffleBtns() {
    this.btns = GetBy.selector('[data-shffl]', this.item);

    this._hoverIn = (e) => this.hovers[e.target.id].play();
    this._hoverOut = (e) => this.hovers[e.target.id].reverse();

    for (let i = 0; i < this.btns.length; i++) {
      const btn = this.btns[i];
      btn.dataset.index = i;

      const target = GetBy.class('__target', btn)[0];
      const shffl = new Shffl(target, {
        origin: Shffl.LEFT,
        duration: .6,
        textFrom: target.innerText,
        textTo: target.innerText
      });

      this.hovers[btn.id] = shffl;
      btn.addEventListener(Basics.mouseOver, this._hoverIn);
      btn.addEventListener(Basics.mouseOut, this._hoverOut);
    }
  }

  dispose() {
    super.dispose();

    for (let i = 0; i < this.btns.length; i++) {
      const btn = this.btns[i];
      btn.removeEventListener(Basics.mouseOver, this._hoverIn);
      btn.removeEventListener(Basics.mouseOut, this._hoverOut);
    }
  }
}

Scroll._registerClass('block-about', ScrollItem__BlockAbout);
