import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Bio from '../layout/Bio';
import Sidemenu from '../layout/Sidemenu';
import Default from './Default';
import Scene from '../3D/Scene';
import AnimationController from '../_app/cuchillo/3D/AnimationController';
import Overlay from '../layout/Overlay';

export default class Works extends Default {
  constructor() {
    super();

    document.body.classList.add('--works');
  }

  beforeShow() {
    super.beforeShow();
    Bio.hide();
    Overlay.directHide();
    if (!this.isFirstTime) Sidemenu.show();
  }

  afterShow() {
    super.afterShow();

    if (this.isFirstTime) Sidemenu.show();

    AnimationController.playPageAnimation('sorpresa');
  }

  afterHide() {
    super.afterHide();
    document.body.classList.remove('--works');
  }
}

ControllerPage._addPage('works', Works);
