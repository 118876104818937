import gsap from 'gsap';
import { LoopOnce } from 'three';
import { Basics, isSmartphone } from '../core/Basics';
import { GLITCH_SETTINGS } from './materials/GlitchMaterial';
import { Functions } from '../utils/Functions';

const PAGE_INACTIVITY_TIMEOUT = 65000;
const ANIAMTION_DELAY_TIMER = 5000;

export default class AnimationController {
    static animationMixer = null;            
    static currentAnimation = null;
    static isLoaded = false;
    static _waitCall;
    
    static STATE_IDLE = "idle";
    static STATE_RUNNING = "running";
    static STATE_PAGE = "page";

    static idTimer;
    static state;
    
    static animationsLoopInfo = {
        all:{
            items:[],
            current:0,
            total:0
        },
        page:{
            items:[],
            current:0,
            total:0
        },
        idle:{
            items:[],
            current:0,
            total:0
        },
        loop:{
            items:[],
            current:0,
            total:0
        }
    }
    static animations = {
        all:{},
        page:{},
        idle:{},
        loop:{}
    }

    static _getRandomAnimation(type) {
        if(!type) type = "all";

        if(this.animationsLoopInfo[type].current >= this.animationsLoopInfo[type].total) {
            this.animationsLoopInfo[type].current = 0;
            this.animationsLoopInfo[type].items = Functions.arrayRandom(this.animationsLoopInfo[type].items);
        }
        
        const animation = this.animations[type][this.animationsLoopInfo[type].items[this.animationsLoopInfo[type].current]];
        this.animationsLoopInfo[type].current++
        
        return animation;
    }
   
    static registerAnimation(animation, type, id) {
        this.animations.all[id] = animation;
        this.animations[type][id] = animation;

        this.animationsLoopInfo.all.total =  this.animationsLoopInfo.all.items.push(id);
        this.animationsLoopInfo[type].total =  this.animationsLoopInfo[type].items.push(id);
    }

    static initMixer(__mixer) {
        this.animationMixer = __mixer;
        this.animationMixer.addEventListener('finished', this.finishAnimation);
        this.isLoaded = true;
    }

    static play() {
        if(!this.isLoaded) {
            this._waitCall = ()=> {
                AnimationController.play();
            }
            return;
        }

        if(this.state === this.STATE_RUNNING) return;
        this.playRandom();
    }

    static playRandom() {
        this.state = this.STATE_RUNNING;
        this.playAnimation(this._getRandomAnimation("loop"), .5, false);
    }

    static playIdle() {
        this.state = this.STATE_IDLE;
        this.playAnimation(this._getRandomAnimation("idle"), .5, false);
    }

    static playPageAnimation(animationName) {
        if(!this.isLoaded) {
            this._waitCall = ()=> {
                AnimationController.playPageAnimation(animationName);
            }
            return;
        }
        
        this.stopLoop();

        this.state = this.STATE_PAGE;
        
        const actionTo = this.animations.page[animationName];
        if (actionTo) {
            this.playAnimation(actionTo, .5, false);
        } else {
            console.log('Page animation not registered.');
        }
    }

    static stopLoop() {
        clearTimeout(this.idTimer);
    }

    static finishAnimation = (e) => {
        if(this.state === this.STATE_RUNNING) {
            this.playIdle();
        } else if(this.state === this.STATE_IDLE) {
            this.idTimer = setTimeout(()=> {this.playRandom();}, ANIAMTION_DELAY_TIMER);
        }
    }


    static playAnimation(actionTo, time = .5, autoplay = true) {
        if(!this.isLoaded) {
            this._waitCall = ()=> {
                AnimationController.playAnimation(actionTo, time, autoplay)
            }
            return;
        }

        actionTo.setLoop(LoopOnce, 1);
        actionTo.clampWhenFinished = true;

        if (this.currentAnimation) this.currentAnimation.fadeOut(time);

        actionTo
            .reset()
            .setEffectiveTimeScale(1)
            .setEffectiveWeight(1)
            .fadeIn(time)
            .play();

        this.currentAnimation = actionTo;
    }

    static loop() {
        if (this.animationMixer) {
            if(!isSmartphone)   this.animationMixer.update(0.02);
            else this.animationMixer.update(0.02);
        }
    }

    ///MAS ADELANTE
      /* static startInactivityTimer() {
        this.inactivityTimer = setTimeout(() => {
            this.playInactivityAnimation();
        }, PAGE_INACTIVITY_TIMEOUT);
    }

static stop() {
        if (this.isPlaying) {
            this.stopInactivityTimer();
            this.removeClickEvent();
        }
    }

    static stopInactivityTimer() {
        clearTimeout(this.inactivityTimer);
    }

    static addClickEvent() {
        document.addEventListener(Basics.clickEvent, this.timeoutClick, {once:true});
    }

    static removeClickEvent() {
        document.removeEventListener(Basics.clickEvent, this.timeoutClick);
    }

    static timeoutClick = () => {
        this.stopInactivityTimer();
        this.startInactivityTimer();
    };

    static inactivityClick = () => {
        GLITCH_SETTINGS.glitch = false;
        gsap.to(GLITCH_SETTINGS, { mixBW: 0, duration: .2 });
        document.removeEventListener(Basics.clickEvent, this.inactivityClick);
    };

    static playInactivityAnimation() {
        console.log('No Page interaction: Play inactivity Animation');

        GLITCH_SETTINGS.glitch = true;
        gsap.to(GLITCH_SETTINGS, { mixBW: 1, duration: .3 });
        document.addEventListener(Basics.clickEvent, this.inactivityClick);

        
    }*/
}
