import gsap, { Power3, Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

import { C, GetBy } from '../_app/cuchillo/core/Element';
import _Sidemenu from '../_app/cuchillo/layout/Sidemenu';
import { Acordion } from '../_app/cuchillo/components/Acordions';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';

export default class Sidemenu extends _Sidemenu {
  static content = GetBy.class('content', this.container)[0];
  static filters = GetBy.class('__filter', this.container);
  static projects = GetBy.class('__project', this.container);
  static acordions = [];
  static hovers = [];
  static width = 0;
  static shffl = [];

  static _calls = {
    filter: (e) => this.filterProjects(e.target)
  }

  static init() {
    super.init();

    C.forEach('[data-acordion-sidemenu]', (acc) => {
      const accord = new Acordion(acc, () => {
        const a = GetBy.selector('a', acc)[0];

        if (isMobile) {
          a.click();
        } else {
          ControllerPage.changePage(a.href);
        }

        this.acordions.map(a => {
          if (a.container !== acc) a.hide();
        });
      })
      this.acordions.push(accord);
    });

    this.addEventListeners();
    this.setShffleBtns();
    this.setupText();
  }

  static addEventListeners() {
    for (let i = 0; i < this.filters.length; i++) {
      const filter = this.filters[i];
      filter.addEventListener(Basics.clickEvent, this._calls.filter);
    }
  }

  static setShffleBtns() {
    const btns = GetBy.selector('[data-sidemenu-shffl]');

    this._hoverIn = (e) => this.hovers[e.target.id].play();
    this._hoverOut = (e) => this.hovers[e.target.id].reverse();

    for (let i = 0; i < btns.length; i++) {
      const btn = btns[i];
      btn.dataset.index = i;

      const target = GetBy.class('__target', btn)[0];
      const shffl = new Shffl(target, {
        origin: Shffl.LEFT,
        duration: .6,
        textFrom: target.innerHTML,
        textTo: target.innerHTML
      });

      this.hovers[btn.id] = shffl;
      btn.addEventListener(Basics.mouseOver, this._hoverIn);
      btn.addEventListener(Basics.mouseOut, this._hoverOut);
    }
  }

  static setupText() {
    new SplitText(GetBy.class('top', this.content), { type: 'lines', linesClass: '__target', tag: 'p' });

    const lines = GetBy.class('__target', this.container);
    for (let i = 0; i < lines.length; i++) {
      const txt = lines[i];
      const shffl = new Shffl(txt, {
        origin: Shffl.LEFT,
        duration: 1,
        textFrom: '',
        textTo: txt.innerHTML
      });

      this.shffl.push(shffl);
    }
  }

  static filterProjects(target) {
    const id = target.dataset.categ;
    let delay = 0;

    if (this.countOpen() > 0) {
      delay = .4;
      this.hideAccordions();
    }

    for (let i = 0; i < this.projects.length; i++) {
      const project = this.projects[i];
      const categs = project.dataset.categs.split(',');

      let opacity = 0;
      if (categs.filter(c => c === id) > 0) opacity = 1;

      gsap.set(project, {
        opacity,
        delay,
        onComplete: () => {
          if (opacity === 0) project.style.display = 'none';
          else project.style.display = 'block';
        }
      });
    }

    for (let i = 0; i < this.filters.length; i++) {
      const filter = this.filters[i];
      filter.classList.remove('--active');
    }

    target.classList.add('--active');
  }

  static countOpen() {
    return this.acordions.filter(acc => acc.isOpen).length;
  }

  static hideAccordions() {
    this.acordions.map(acc => acc.hide());
  }

  static show(__d = 0) {
    if (this._state === Sidemenu.STATE_OPEN) return;

    // console.log('Sidemenu Show');

    super.show(__d);

    this.shffl.map((line, i) => {
      line.restart();
      gsap.delayedCall(__d + .2 + i * .09, () => {
        line.play();
      });
    });
  }

  static show__effect(__d = 0) {
    gsap.killTweensOf(this.content);

    this.container.classList.add('--animated');
    gsap.set(this.content, { opacity: 1 });

    this.afterShow();
  }

  static directShow() {
    this.container.style.visibility = 'visible';
    this.container.setAttribute('aria-expanded', 'true');
    this.state = Sidemenu.STATE_OPEN;

    this.shffl.map((line, i) => {
      line.play();
    });

    gsap.set(this.content, { opacity: 1 });
    this.container.classList.add('--animated');

    this.afterShow();
  }

  static hide(__d = 0) {
    if (this._state === Sidemenu.STATE_CLOSE) return;

    // console.log('Sidemenu Show');

    super.hide(__d);

    for (let i = 0; i < this.filters.length; i++) {
      const filter = this.filters[i];
      filter.classList.remove('--active');
    }

    for (let i = 0; i < this.projects.length; i++) {
      const project = this.projects[i];
      project.style.display = 'block';
      gsap.set(project, { opacity: 1 });
    }

    this.hideAccordions();
  }

  static hide__effect(__d = 0) {
    gsap.killTweensOf(this.content);

    gsap.to(this.content, { opacity: 0, duration: .1, ease: Power2.easeOut });
    this.container.classList.remove('--animated');
    gsap.set(this.container, {
      delay: .3,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  static directHide() {
    super.directHide();
    this.afterHide();
    gsap.set(this.content, { opacity: 0 });

  }

  static resize() {
    super.resize();
    this.acordions.map(acc => acc.resize());
    this.width = this.container.getBoundingClientRect().width;
  }
}
