import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { GLITCH_SETTINGS } from '../_app/cuchillo/3D/materials/GlitchMaterial';
import Default from './Default';
import gsap from 'gsap';
import AnimationController from '../_app/cuchillo/3D/AnimationController';
import Scene from '../3D/Scene';
import Overlay from '../layout/Overlay';

export default class NotFound extends Default {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor() {
    super();
  }

  beforeShow() {
    super.beforeShow();

    GLITCH_SETTINGS.glitch = true;
    GLITCH_SETTINGS.mixBW = 1;
    Overlay.directHide();
  }


  afterShow() {
    super.afterShow();

    AnimationController.playPageAnimation('resoplido');
  }

  beforeHide() {
    super.beforeHide();

    GLITCH_SETTINGS.glitch = false;
  }

  afterHide() {
    super.afterHide();

    gsap.to(GLITCH_SETTINGS, { mixBW: 0, duration: .3 });
  }
}

ControllerPage._addPage('not-found', NotFound);
