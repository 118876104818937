import gsap, { Power2, Power3 } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
gsap.registerPlugin(DrawSVGPlugin);

import { Videos } from '../_app/cuchillo/components/Videos';
import { isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Scene from '../3D/Scene';
import Header from '../layout/Header';
import RenderTargetGlitchSketch from '../3D/RenderTargetGlitchSketch';

export default class Default extends Page {
  logoVectors;

  constructor() {
    super();

    this.logoVectors = GetBy.selector('#scene-target [data-3d-name] .icon--logo-hollow path');

    Videos.init();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);

    if (this.isFirstTime) {
      //gsap.set(GetBy.selector('#scene-target [data-3d-name] .icon--logo'), { opacity: 0 });
      gsap.set(this.logoVectors, { drawSVG: '0%' });

      Scene.start();
      RenderTargetGlitchSketch.start();
    }
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => {
          for (let i = 0; i < this.logoVectors.length; i++) {
            const element = this.logoVectors[i];
            gsap.to(element, {
              drawSVG: '100%',
              duration: 1.5,
              delay: i * .25,
              ease: Power2.easeInOut
            });
          }

          gsap.delayedCall(.5, () => {
            Scene.show(() => {
              Header.show();
              this.afterShow();
            });
          });

          /*gsap.to(this.logoVectors, {
            drawSVG: '0%',
            delay: 3,
            duration: 1,
            ease: Power2.easeInOut
          });*/
        });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);
