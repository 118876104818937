import Scene from "../3D/Scene";
import { Basics, isSmartphone } from "../_app/cuchillo/core/Basics";

export default class GyroscopeController {
  firstTime = true;
  data = {
    x: 0,
    y: 0
  }

  constructor() {
    this.supported = 'DeviceOrientationEvent' in window;
    this.permissionGranted = false;
    this.rotation = { alpha: null, beta: null, gamma: null };

    if (this.supported && isSmartphone) {
      document.addEventListener(Basics.upEvent, () => {
        if (typeof DeviceMotionEvent.requestPermission === 'function') {

          // Handle iOS 13+ devices.
          DeviceMotionEvent.requestPermission()
            .then((state) => {
              if (state === 'granted') {
                this.init();
              } else {
                console.error('Request to access the orientation was rejected');
              }
            })
            .catch(console.error);
        } else {
          // Handle regular non iOS 13+ devices.
          this.init();
        }

      }, { once: true })

      /*if (typeof DeviceOrientationEvent.requestPermission === 'function') {
          DeviceOrientationEvent.requestPermission().then(permissionState => {
              if (permissionState === 'granted') {
                  this.permissionGranted = true;
                  this.init();
              }
          }).catch(console.error);
      } else {
          this.permissionGranted = true;
          this.init();
      }*/
      // } else {
      //   console.error('El giroscopio no está soportado en este dispositivo.');
    }
  }

  init() {
    window.addEventListener('deviceorientation', function (eventData) {

      /*if(this.firstTime && eventData.gamma) {
        this.firstTime = false;
        this.data = {
          x: eventData.gamma,
          y: eventData.beta
        }
      }*/

      // alert(eventData.gamma, (eventData.gamma) * .1)

      Scene.group.rot.y = (eventData.gamma) * -.0035;
      Scene.group.rot.x = (eventData.beta) * -.0015;

    }, false);

    /* window.addEventListener('devicemotion', event => {
         this.rotation.alpha = event.alpha;
         this.rotation.beta = event.beta;
         this.rotation.gamma = event.gamma;

         // Aquí puedes realizar las acciones deseadas con los valores de rotación
         // Por ejemplo, actualizar la orientación de un objeto en una escena 3D.
         console.log("update")

         this.updateRotation(this.rotation);
     });*/
  }
}
