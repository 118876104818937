import { AnimationMixer, LoopOnce, LoopRepeat } from 'three';
import gsap from 'gsap';

import WebGLObject from './WebGLObject';
import { ANIMATION_SETTINGS, SETTINGS } from '../../../3D/constants';
import TexturesController from './TexturesController';
import GeometryController from './GeometryController';
import AnimationController from './AnimationController';

export default class SceneGLB extends WebGLObject {
    object;
    diffusion;
    normal;
    roughness;
    metalness;
    envMap;

    model;
    matcap;

    // actions = [];
    // clips = [];
    // animate = false;
    // currentAnimation = -1;
    // animationMixer;

    constructor(opts = {}) {
        super(opts);

        this.model = opts.model;
        this.matcap = opts.matcap;
        this.diffusion = opts.diffusion;
        this.normal = opts.normal;
        this.roughness = opts.roughness;
        this.metalness = opts.metalness;
        this.envMap = opts.envMap;

        this.sizeFactor = opts.cols ? opts.cols : SETTINGS.factor;
    }

    init() {
        return new Promise((resolve, reject) => {
            GeometryController.load(
                {
                    src: this.model,
                    call: (gltf) => {
                        this.object = gltf;

                        this.mesh = gltf.scene.children[0];
                        this.add(gltf.scene);

                        if (this.material) {
                            this.mesh.material = this.material;
                            this.material.needsUpdate = true;
                        }

                        if (this.opts.shadows) {
                            gltf.scene.traverse(child => {
                                if (child.isMesh) {
                                    child.castShadow = true;
                                    child.receiveShadow = true;
                                }
                            });
                        }

                        this.size.copy(this.mesh.scale);

                        this.resize();
                        this.loadTextures();

                        resolve();
                    }
                },
                undefined,
                (error) => {
                    reject(error);
                });
        });
    }

    loadAnimations() {
        // this.animate = true;
        // AnimationController.animationMixer = new AnimationMixer(this.object.scene);

        // const animations = this.object.animations;
        // animations.map(clip => {
        //     console.log(clip, clip.name);
        //     const action = AnimationController.animationMixer.clipAction(clip);
        //     // this.actions.push(action);
        //     AnimationController.registerAnimation(action);
        // });
    }

    // update() {
    //     super.update();

    // if (!this.animate) return;

    // if (!AnimationController.isPlaying) return;

    // if (this.currentAnimation !== AnimationController.currentIndex) {
    //     const actionFrom = this.actions[this.currentAnimation];
    //     const actionTo = this.actions[AnimationController.currentIndex];

    //     actionTo.setLoop(LoopOnce);
    //     actionTo.clampWhenFinished = true;
    //     actionTo.play();

    //     if (actionFrom) {
    //         actionTo.crossFadeFrom(actionFrom, 2);
    //         gsap.delayedCall(2, () => { actionFrom.stop(); });
    //     }

    //     this.currentAnimation = AnimationController.currentIndex;
    // }
    // }

    loadTextures() {
        if (this.matcap) {
            TexturesController.load({
                src: this.matcap,
                material: this.material,
                attribute: 'matcap'
            });
        }
    }

    resize(offsetX = 0, offsetY = 0, offsetScale = 1) {
        if (!this.object) return;

        const { x, y, width, height } = this.dom.getBoundingClientRect();

        if (this.hasMove) {
            const position = this.domPositionTo3D(x, y);
            this.position.x = (position.x + width * .5) - offsetX;
            this.position.y = (position.y - height * .5) - offsetY;
            this.pos.x = this.position.x;
            this.pos.y = this.position.y;
        } else {
            this.position.x = 0;
            this.position.y = 0;
            this.pos.x = 0;
            this.pos.y = 0;
        }

        if (this.opts.cols) {
            const scale = (width / this.opts.cols);
            this.object.scene.scale.set(scale, scale, scale);
        } else {
            const factor = width / SETTINGS.factor;
            this.object.scene.scale.set(factor * this.size.x, factor * this.size.y, factor * this.size.z);
        }
    }
}
