import { isMobile, isSmartphone } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Bio from '../layout/Bio';
import Overlay from '../layout/Overlay';
import Sidemenu from '../layout/Sidemenu';
import Wrap from '../layout/Wrap';
import Default from './Default';

export default class Project extends Default {
  constructor() {
    super();
  }

  beforeShow() {
    super.beforeShow();

    document.body.classList.add('--project');

    Bio.hide();

    if (isSmartphone) {
      Sidemenu.hide();
    } else if (!this.isFirstTime) {
      Sidemenu.show();
    }
  }

  hide__effect() {
    Overlay.show(() => {
      Scroll.hide();
      Wrap.hide(() => { this.afterHide(); });
    });
  }

  afterShow() {
    super.afterShow();

    if (this.isFirstTime && !isSmartphone) Sidemenu.show();

    Overlay.hide(() => { this.container.classList.add('--show') });
  }

  afterHide() {
    super.afterHide();
    document.body.classList.remove('--project');
  }
}

ControllerPage._addPage('project', Project);
