import { NoBlending, ShaderMaterial } from 'three';

import CanvasColorTexture from "../textures/CanvasColorTexture";

export const GLITCH_VERTEX = `
    varying vec2 vUv;

    void main() {
        vUv = uv;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const GLITCH_FRAGMENT = `
    uniform sampler2D texture1;
    uniform float distortion;
    uniform float distortion2;
    uniform float distortionScale;
    uniform float distortionScale2;
    uniform float speed;
    uniform float time;
    uniform float mixBW;

    varying vec2 vUv;

    uniform vec2 resolution;

    vec3 mod289(vec3 x) {
        return x - floor(x * (1.0 / 289.0)) * 289.0;
    }

    vec2 mod289(vec2 x) {
        return x - floor(x * (1.0 / 289.0)) * 289.0;
    }

    vec3 permute(vec3 x) {
        return mod289(((x*34.0)+1.0)*x);
    }

    float snoise(vec2 v) {
        const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                            0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                            -0.577350269189626,  // -1.0 + 2.0 * C.x
                            0.024390243902439); // 1.0 / 41.0
        // First corner
        vec2 i  = floor(v + dot(v, C.yy) );
        vec2 x0 = v -   i + dot(i, C.xx);

        // Other corners
        vec2 i1;
        //i1.x = step( x0.y, x0.x ); // x0.x > x0.y ? 1.0 : 0.0
        //i1.y = 1.0 - i1.x;
        i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
        // x0 = x0 - 0.0 + 0.0 * C.xx ;
        // x1 = x0 - i1 + 1.0 * C.xx ;
        // x2 = x0 - 1.0 + 2.0 * C.xx ;
        vec4 x12 = x0.xyxy + C.xxzz;
        x12.xy -= i1;

        // Permutations
        i = mod289(i); // Avoid truncation effects in permutation
        vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
            + i.x + vec3(0.0, i1.x, 1.0 ));

        vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
        m = m*m ;
        m = m*m ;

        // Gradients: 41 points uniformly over a line, mapped onto a diamond.
        // The ring size 17*17 = 289 is close to a multiple of 41 (41*7 = 287)

        vec3 x = 2.0 * fract(p * C.www) - 1.0;
        vec3 h = abs(x) - 0.5;
        vec3 ox = floor(x + 0.5);
        vec3 a0 = x - ox;

        // Normalise gradients implicitly by scaling m
        // Approximation of: m *= inversesqrt( a0*a0 + h*h );
        m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );

        // Compute final noise value at P
        vec3 g;
        g.x  = a0.x  * x0.x  + h.x  * x0.y;
        g.yz = a0.yz * x12.xz + h.yz * x12.yw;
        return 130.0 * dot(m, g);
    }

    // Función para aplicar el tonemapping
    vec3 toneMapping(vec3 color) {
        // Aquí puedes aplicar el tonemapping deseado, como el gamma correction
        // Por ejemplo, simplemente podrías elevar a la potencia de 1/2.2 para convertir de linear a sRGB.
        return pow(color, vec3(1.0 / 2.2));
    }

    // Función para decodificar el color de la textura desde sRGB
    vec3 gammaDecode(vec3 color) {
        return pow(color, vec3(2.2));
    }

    // Función para codificar el color en sRGB
    vec3 gammaEncode(vec3 color) {
        return pow(color, vec3(1.0 / 2.2));
    }

    void main() {
        vec2 uv = vUv;
        
        // Create large, incidental noise waves
        float noise = max(0.0, snoise(vec2(time, uv.y * distortionScale)) - 0.3) * distortion;
        
        // Offset by smaller, constant noise waves
        noise += (snoise(vec2(time * 10.0 * speed, uv.y * distortionScale2)) - 0.5) * distortion2;
        
        // Apply the noise as X displacement for every line
        float xpos = uv.x - noise * noise * 0.25;

        vec4 texColor = texture2D(texture1, vec2(xpos, uv.y));
        texColor.rgb = gammaDecode(texColor.rgb);

        // Convertir el color a blanco y negro (luminancia)
        float luminance = dot(texColor.rgb, vec3(0.2126, 0.7152, 0.0722));
        
        // Establecer el color final
        vec3 finalColor = mix(texColor.rgb, vec3(luminance), mixBW);
        
        // Aplicar el tonemapping
        finalColor = toneMapping(finalColor);

        // Codificar el color resultante en sRGB
        finalColor = gammaEncode(finalColor);

        // Mix in some random interference for lines
        vec3 noisedColor = mix(finalColor.rgb, vec3(0.0), noise * 0.3);
        gl_FragColor = vec4(noisedColor, texColor.a);

        // Apply a line pattern every 4 pixels
        if (floor(mod(gl_FragCoord.y * 0.25, 2.0)) == 0.0) {
            gl_FragColor.rgb *= 1.0 - (0.15 * noise);
        }
    }
`;

export const GLITCH_SETTINGS = {
    distortion: 2.05,
    distortion2: 0.030,
    distortionScale: .3,
    distortionScale2: 2.4,
    time: 0,
    speed: 1.84,
    glitch: false,
    mixBW: 0
}

export const GLITCH_UNIFORMS = {
    texture1: { type: 't', value: new CanvasColorTexture().texture },
    distortion: { type: 'f', value: 0 },
    distortion2: { type: 'f', value: 0 },
    distortionScale: { type: 'f', value: 0 },
    distortionScale2: { type: 'f', value: 0 },
    speed: { type: 'f', value: 0 },
    time: { type: 'f', value: 0 },
    mixBW: { type: 'f', value: 0 },
    resolution: {
        type: 'v2',
        value: { x: 1, y: 1 }
    },
};

export default class GlitchMaterial extends ShaderMaterial {
    constructor() {
        super({
            uniforms: GLITCH_UNIFORMS,
            vertexShader: GLITCH_VERTEX,
            fragmentShader: GLITCH_FRAGMENT,
            blending: NoBlending,
            depthWrite: false,
            depthTest: false
        });
    }
}

// const glitch = this.pane.addFolder({ title: 'Glitch', expanded: true });
// glitch.addInput(GLITCH_SETTINGS, 'distortion', {
//     label: 'Distortion',
//     step: .001,
//     min: 0,
//     max: 10,
// });
// glitch.addInput(GLITCH_SETTINGS, 'distortionScale', {
//     label: 'Distortion Scale',
//     step: .001,
//     min: 0,
//     max: 40,
// });
// glitch.addInput(GLITCH_SETTINGS, 'distortion2', {
//     label: 'Distortion 2',
//     step: .001,
//     min: 0,
//     max: 10,
// });
// glitch.addInput(GLITCH_SETTINGS, 'distortionScale2', {
//     label: 'Distortion Scale 2',
//     step: .001,
//     min: 0,
//     max: 40,
// });
// glitch.addInput(GLITCH_SETTINGS, 'speed', {
//     label: 'Speed',
//     step: .001,
//     min: 0,
//     max: 2,
// });