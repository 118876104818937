import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

import { C, GetBy } from '../_app/cuchillo/core/Element';
import _Bio from '../_app/cuchillo/layout/Bio';
import { Acordion } from '../_app/cuchillo/components/Acordions';
import { Basics } from '../_app/cuchillo/core/Basics';
import { Shffl } from '../_app/cuchillo/components/Shffl';

export default class Bio extends _Bio {
  static content = GetBy.class('content', this.container)[0];
  static acordions = [];
  static hovers = [];
  static width = 0;
  static shffl = [];

  static init() {
    super.init();

    C.forEach('[data-acordion]', (acc) => {
      this.acordions.push(new Acordion(acc));
    });

    this.setShffleBtns();
    this.setupText();
  }

  static setShffleBtns() {
    const btns = GetBy.selector('[data-bio-shffl]');

    this._hoverIn = (e) => this.hovers[e.target.id].play();
    this._hoverOut = (e) => this.hovers[e.target.id].reverse();

    for (let i = 0; i < btns.length; i++) {
      const btn = btns[i];
      btn.dataset.index = i;

      const target = GetBy.class('__target', btn)[0];
      const shffl = new Shffl(target, {
        origin: Shffl.LEFT,
        duration: .5,
        textFrom: target.innerHTML,
        textTo: btn.getAttribute('data-text-to') || target.innerHTML
      });

      this.hovers[btn.id] = shffl;
      btn.addEventListener(Basics.mouseOver, this._hoverIn);
      btn.addEventListener(Basics.mouseOut, this._hoverOut);
    }
  }

  static setupText() {
    new SplitText(GetBy.class('top', this.content), { type: 'lines', linesClass: '__target', tag: 'p' });
    new SplitText(GetBy.class('text', this.content), { type: 'lines', linesClass: '__target', tag: 'p' });

    const lines = GetBy.class('__target', this.container);
    for (let i = 0; i < lines.length; i++) {
      const txt = lines[i];
      const shffl = new Shffl(txt, {
        origin: Shffl.LEFT,
        duration: .6,
        textFrom: '',
        textTo: txt.innerHTML
      });

      this.shffl.push(shffl);
    }
  }

  static countOpen() {
    return this.acordions.filter(acc => acc.isOpen).length;
  }

  static hideAccordions() {
    this.acordions.map(acc => acc.hide());
  }

  static show(__d = 0) {
    super.show(__d);

    this.shffl.map((line, i) => {
      line.restart();
      gsap.delayedCall(__d + .2 + i * .09, () => {
        line.play();
      });
    });
  }

  static show__effect(__d = 0) {
    gsap.killTweensOf(this.content);

    this.container.classList.add('--animated');
    gsap.set(this.content, { opacity: 1 });

    this.afterShow();
  }

  static directShow() {
    this.container.style.visibility = 'visible';
    this.container.setAttribute('aria-expanded', 'true');
    this.state = Bio.STATE_OPEN;

    this.shffl.map((line, i) => {
      line.play();
    });

    gsap.set(this.content, { opacity: 1 });
    this.container.classList.add('--animated');

    this.afterShow();
  }

  static hide(__d = 0) {
    super.hide(__d);

    this.hideAccordions();
  }

  static hide__effect(__d = 0) {
    gsap.killTweensOf(this.content);

    gsap.to(this.content, { opacity: 0, duration: .1, ease: Power2.easeOut });
    this.container.classList.remove('--animated');
    gsap.set(this.container, {
      delay: .3,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  static directHide() {
    super.directHide();
    this.afterHide();
    gsap.set(this.content, { opacity: 0 });
  }

  static resize() {
    super.resize();
    this.acordions.map(acc => acc.resize());
    this.width = this.container.getBoundingClientRect().width;
  }
}
