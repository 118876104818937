import AnimationController from '../_app/cuchillo/3D/AnimationController';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Bio from '../layout/Bio';
import Overlay from '../layout/Overlay';
import Sidemenu from '../layout/Sidemenu';
import Default from './Default';

export default class Home extends Default {

  idTimer

  constructor() {
    super();

    document.body.classList.add('--home');
  }

  beforeShow() {
    super.beforeShow();
    Sidemenu.hide();
    Bio.hide();
    Overlay.directHide();
  }

  afterShow() {
    super.afterShow();
    if (isMobile) document.body.classList.add("__noScroll");
    AnimationController.play();
  }

  playAnimation() {

    AnimationController.play();

    clearTimeout(this.idTimer);
    this.idTimer = setTimeout(() => { this.playIdle(); }, 2000);
  }

  playIdle() {
    // console.log("playIdle")
    AnimationController.playIdle();

    clearTimeout(this.idTimer);
    this.idTimer = setTimeout(() => { this.playAnimation(); }, 2000);
  }

  afterHide() {
    clearTimeout(this.idTimer);
    super.afterHide();

    document.body.classList.remove("__noScroll");
    document.body.classList.remove('--home');
  }
}

ControllerPage._addPage('home', Home);
