import { AnimationMixer } from 'three';

import SceneGLB from '../_app/cuchillo/3D/SceneGLB';
import AnimationController from '../_app/cuchillo/3D/AnimationController';
import GeometryController from '../_app/cuchillo/3D/GeometryController';

// const LOOP_ANIMATIONS = ['bostezo'];
const IDLE_ANIMATION = 'idle';
const LOOP_ANIMATIONS = ['bostezo', 'parpadeo', 'resoplido', 'estirando'];
const PAGE_ANIMATIONS = ['sonrisa', 'sorpresa', 'resoplido', 'bostezo', 'parpadeo', 'estirando'];

export default class HeadGLB extends SceneGLB {
    constructor(opts = {}) {
        super(opts);

        this.animations = opts.animations;
    }

    loadAnimations() {
        const animModel = new Promise((resolve, reject) => {
            GeometryController.load(
                {
                    src: this.animations,
                    call: (gltf) => {

                        AnimationController.initMixer(new AnimationMixer(this.object.scene.children[0]));

                        const animations = gltf.animations;
                        animations.map(clip => {
                            const action = AnimationController.animationMixer.clipAction(clip);
                            const name = clip.name.toLowerCase();

                            LOOP_ANIMATIONS.map((anim, index) => {
                                if (name.indexOf(anim) > -1) {
                                    AnimationController.registerAnimation(action, 'loop', LOOP_ANIMATIONS[index]);
                                }
                            });

                            if (name.indexOf(IDLE_ANIMATION) > -1) {
                                AnimationController.idleAnimation = action;
                                AnimationController.registerAnimation(action, 'idle', 'idle');
                            }

                            PAGE_ANIMATIONS.map((anim, index) => {
                                if (name.indexOf(anim) > -1) {
                                    AnimationController.registerAnimation(action, 'page', PAGE_ANIMATIONS[index]);
                                }
                            });
                        });

                        if(AnimationController._waitCall) AnimationController._waitCall();

                        resolve();
                    }
                },
                undefined,
                (error) => {
                    reject(error);
                });
        });

        animModel.then();
    }
}
