import { gsap, Power0, Power2 } from 'gsap';

import { isTouch } from '../core/Basics';
import VScroll from '../scroll/VScroll';
import { Accessibility } from '../core/Accessibility';
import { GetBy } from '../core/Element';
import EventDispatcher from '../core/EventDispatcher';
import { Scroll } from '../scroll/Scroll';

export default class Bio {
  static container = GetBy.id('Bio');

  static ON_SHOW = 'onshow';
  static ON_SHOW_END = 'onshowend';
  static ON_HIDE = 'onhide';
  static ON_HIDE_END = 'onhideend';

  static STATE_OPEN = 'OPEN';
  static STATE_CLOSE = 'CLOSE';

  static options = {
    container: GetBy.class('content', this.container)[0],
    domResize: GetBy.class('__content', this.container)[0],
    isMain: false
  };

  _texts;
  _state;

  static get isOpen() { return this._state === Bio.STATE_OPEN; }
  static get state() { return this._state };
  static set state(__state) {
    if (this._state === __state) return;

    this._state = __state;

    if (this.isOpen) {
      Accessibility.trap(this.container);
      EventDispatcher.dispatchEvent(Bio.ON_SHOW);
    } else {
      Accessibility.removeTrap();
      EventDispatcher.dispatchEvent(Bio.ON_HIDE);
    }
  }

  static init() {
    Bio.directHide();

    /* BUG RESIZE */
    if (!isTouch) {
      this.engine = new VScroll(this.options);
      this.engine.addAll('[scroll-bio-item]');
      this.engine.resize();
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  static toggleState() {
    if (!this.isOpen) this.show();
    else this.hide();
  }

  static show(__d = 0) {
    this.container.style.visibility = 'visible';
    this.container.setAttribute('aria-expanded', 'true');
    this.state = Bio.STATE_OPEN;
    this.show__effect();
  }

  // SHOW
  static show__effect(__d = 0) {
    gsap.set(this.container, { alpha: 0 });

    gsap.to(this.container, {
      alpha: 1,
      duration: 0.3,
      delay: __d,
      ease: Power0.easeOut
    });

    this._texts.map((item, i) => {
      gsap.to(item, {
        y: 0,
        duration: 1,
        delay: __d + 0.05 * i,
        ease: Power2.easeInOut
      });
    });

    gsap.delayedCall(0.05 * this._texts.length, () => { this.afterShow(); });
  }

  static afterShow() {
    EventDispatcher.dispatchEvent(Bio.ON_SHOW_END);
    Scroll.resize();
    this.enableScroll();
  }

  // HIDE
  static hide(__d = 0) {
    this.state = Bio.STATE_CLOSE;
    this.hide__effect();
  }

  static directHide() {
    this._state = Bio.STATE_CLOSE;
    this.afterHide();
  }

  static hide__effect(__d = 0) {
    gsap.to(this.container, {
      alpha: 0,
      delay: 0.3,
      duration: 0.3,
      delay: __d,
      ease: Power2.easeOut,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  static afterHide() {
    this.container.style.visibility = 'hidden';
    this.container.setAttribute('aria-expanded', 'false');
    EventDispatcher.dispatchEvent(Bio.ON_HIDE_END);
    Scroll.resize();
    this.disableScroll();
  }

  static enableScroll() {
    if (this.engine && !this.engine.enabled) this.engine.enabled = true;
  }

  static disableScroll() {
    if (this.engine && this.engine.enabled) this.engine.enabled = false;
  }

  static loop() {
    if (this.engine && this.engine.enabled) this.engine.loop();
  }

  static resize() {
    if (this.engine && this.engine.enabled) this.engine.resize();
  }
}
