import { DRACO_LOADER, GLTF_LOADER, OBJ_LOADER } from '../../../3D/constants';

export default class GeometryController {
  static LOADED = 'loaded';
  static LOADING = 'loading';
  static EMPTY = 'empty';

  static opts = {
    max: 10,
    preloads: 5
  }

  static getLoader(src) {
    const extension = src.split('.').pop().toLowerCase();

    switch (extension) {
      case 'glb':
        return DRACO_LOADER;
      case 'gltf':
        return GLTF_LOADER;
      default:
        return DRACO_LOADER;
    }
  }

  static isPreload = false;
  static actual = 0;
  static geometries = {};
  static loadQueue = [];

  static _getId(__src) { return __src.split('/').join('_').split('.').join('_').toLowerCase(); }

  static start(opts) {
    this.opts = {
      ...this.opts,
      ...opts
    };

    this._nextLoad();
  }

  static load(__opts) {
    const { src, call } = __opts;
    const id = this._getId(src);

    const loaded = (geometry) => {
      if (call) {
        call(geometry);
      }
    }
    const cancel = (geometry) => { }

    /*if (this.geometries[id]) {
      const item = this.geometries[id];
  
      if (item.state === GeometryController.LOADED) {
        loaded(item.geometry);
      } else {
        item.onLoaded.push(loaded);
        item.onCancel.push(cancel);
      }

    } else {*/
    const newItem = {
      id,
      src,
      onLoaded: [loaded],
      onCancel: [cancel],
      state: GeometryController.EMPTY,
      geometry: null,
      cancel: false
    };

    this.geometries[id] = newItem;
    this.loadQueue.push(id);
    // }

    if (!this.isPreload) {
      this._nextLoad();
    }

    return id;
  }

  static _itemLoaded(__item, __geometry) {
    const { onLoaded } = __item;

    __item.state = GeometryController.LOADED;
    __item.geometry = __geometry;

    if (onLoaded) {
      onLoaded.map(call => {
        call(__geometry)
      })
    }
  }

  static _nextLoad() {
    if (this.actual < this.opts.max && this.loadQueue.length > 0) {
      const id = this.loadQueue.shift();
      const item = this.geometries[id];

      if (item.cancel) {
        this._nextLoad();
        return;
      }

      this.actual++;
      item.state = this.LOADING;

      this.getLoader(item.src).load(item.src, (geometry) => {
        if (!item.cancel) {
          this._itemLoaded(item, geometry);
        }

        this.actual--;
        this._nextLoad();
      });
    }

    if (this.loadQueue.length === 0 && this.isPreload) {
      this.isPreload = false;
      this.opts.call();
    }
  }

  /*static cancel(__id, __onlyLoading = false) {
    const item = this.geometries[id];
    const { onCancel } = item;

    if(!item) return;

    item.cancel = true;

    if(item.state === GeometryController.LOADING) {
      item.texture.abort();
      this.actual--;
    } else if(item.state === GeometryController.LOADED && !__onlyLoading) {
      item.texture.dispose();
      item.texture = null;
    }

    if(onCancel) {
      onCancel.map(call => call());
    }

    this.geometries[id].state = this.EMPTY;
  }*/

  static reset() {
    this.geometries = {};
    this.loadQueue = [];
    this.isPreload = true;
  }
}